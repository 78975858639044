//const jsonpath = require('../node_modules/jsonpath');

function enrich(tour, url, name, id) {
    // Remove json extension
    console.log(url);
    tour.folder = name;
    console.log(tour.folder);
    tour.id = id;
    return tour;
}

function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

function getLang() {
    return getCookie("lang");
}

function setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function deleteCookie(name) { setCookie(name, '', -1); }

function setLang(lang) {
    setCookie("lang", lang, 90);
}

module.exports = {
    enrich, 
    getCookie, 
    setCookie, 
    deleteCookie, 
    setLang, 
    getLang
};
