<template>
  <div class="home container">
    <div style="background-color:black;position:relative;">
      <img :src="`/assets/`+ tour.folder + '/' + tour.Image[imageId]" style="margin:0;padding:0;" v-on:click="openGallery" />
      <div class="minis">
          <vue-picture-swipe ref="pictureSwipe" :items="images" :options="{closeEl:true, captionEl: true, fullscreenEl: false, zoomEl: true, shareEl: false, counterEl: true, arrowEl: true, preloaderEl: true}"></vue-picture-swipe>
      </div>
      <section class="hero is-primary">
        <div class="hero-body">
          <p class="title">
            {{tour.NameRoute}}
          </p>
          <p  v-if="lang == 'french' " class="subtitle">
            {{tour.TextFR}}
          </p>
          <p  v-else class="subtitle">
            {{tour.TextEN}}
          </p>
          <p><i class="fas fa-map-marker"></i> {{tour.NbStops}} points - <i class="fas fa-walking"></i> {{tour.Distance}} - <i class="fas fa-clock"></i> {{tour.Duration}}</p>
        </div>
        <router-link :to="'/Plan/' + tour.folder">
          <button v-if="lang == 'french' " class="button is-large is-link is-fullwidth">Commencer la visite</button>
          <button v-else class="button is-large is-link is-fullwidth">Start</button>
        </router-link>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import jsonpath from "../../node_modules/jsonpath";
import _ from "../../node_modules/lodash";
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import * as DefaultPhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default';

export default {
  name: "Intro",
  components: {
  },
  data() {
    return {
      tour: {},
      imageUrl: "",
      imageId:0,
      images: []
    };
  },
  methods: {
    videconsole() {
      console.log(this.eglise);
    },
    showImage: function(index) {
      this.imageId = index;
      console.log(this.imageId);
    },
    openPictureSwipe: function() {
      console.log("here");
      console.log(this.$refs.pictureSwipe);
      console.log(this.$refs.pictureSwipe.pswp);
      //jQuery("figure").click();
    },
    openGallery() {
      const pswpElement = document.querySelectorAll('.pswp')[0];
      const gallery = new PhotoSwipe(pswpElement, DefaultPhotoSwipeUI,
        this.images,
        {
          shareEl: false,
          fullscreenEl: false,
          captionEl: false
        });
      gallery.init();
    }
  },
  created() {
    console.log(this.$route.params.tour);
    console.log(this.$parent.$parent.tours);
    this.tourId = this.$route.params.tour ? this.$route.params.tour : "eglise";
    this.stopId = this.$route.params.id ? this.$route.params.id : "stopId";
    this.tour = this.$parent.$parent.tours[this.tourId];
    
    console.log(this.tour);
    let that=this;
    this.tour.Image.forEach(function(element) {
      that.images.push({
        src: `/assets/`+ that.tour.folder + '/' + element,
        thumbnail: `/assets/`+ that.tour.folder + '/' + element,
        w: 1000,
        h: 1000,
        title: element
      });
      //numCallbackRuns++;
    });

    //this.imageUrl = this.tour.imageUrl;
  },
  computed: {
    orderedStopGroups: function () {
      return _.orderBy(this.tour.Stop, 'Title.content', 'asc');
    },
    lang: function() {
      return this.$parent.$parent.lang;
    },
    Image: function() {
      return this.tour.Image[this.imageId];
    }
  }
};
</script>

<style scoped lang="scss">
  .startlink {
    background:#6b0100;
    padding:20px 8px 12px 8px;
    color:white;
    a {
      color:white;
    }
  }
  figure img {
    width:100%;
  }
  .minis {
    background:black;
    //position:absolute;
    //top:10px;
    //right:20px;
    padding:0 5px 0 5px;
    text-align: center;
  }
  .minis img {
    height:30px;
    width:auto;
    margin:5px;
  }
  .minis span {
    margin:0;
    padding:0;
    display:inline-block;
  }
</style>
