<template>
  <div class="stopgroup">
    <div
      style="padding:4px 10px;font-weight:bold;background-color:#dddddd;color:white;"
    >
      <a href="javascript:history.go(-1)"> &lt; RETOUR</a>
    </div>
    <img :src="'/' + imageUrl" style="display:block;width:100%;" />
  </div>
</template>

<script>
// @ is an alias to /src
import jsonpath from "../../node_modules/jsonpath";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      tour: [],
      stopId: "stopId",
      title: "title",
      description: "description",
      stop: {},
      imageId: "",
      imageUrl: "",
      connexions: [],
      audioUrl: "",
      audioName: ""
    };
  },
  created() {
    this.tourId = this.$route.params.tour ? this.$route.params.tour : "folleville_eglise";
    this.stopId = this.$route.params.id ? this.$route.params.id : "stopId";
    this.tour = this.$parent.$parent.tours[this.tourId];

    this.stop = jsonpath.query(this.tour, '$..Stop[?(@.id=="'+this.stopId+'")]')[0];
    this.title = this.stop.Title.content;
    this.imageId = this.stop.AssetRef.id;
    let imageinfo = jsonpath.query(
      this.tour,
      '$..Asset[?(@.id=="' + this.imageId + '")]'
    )[0];
    this.imageUrl = imageinfo.Source[0].uri;
  },
  methods: {},
  computed: {}
};
</script>

<style scoped>
figure.image img {
  width: 100%;
}
</style>
