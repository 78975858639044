<template>
  <div class="home container" :key="refreshKey">
    <div>
    <h3>{{ stop.Number }} {{ stop.Title }}</h3>

    <div v-if="!isQuestion" style="width:100%;height:50vh;" v-on:click="openGallery">
      <img class="image" style="width:100%;height:100%;object-fit:cover;object-position:center;" :src="`/assets/${tourId}/${stop.Ref}/${imageUrl}`" />
    </div>
    <div v-if="!isQuestion" class="minis">
          <vue-picture-swipe ref="pictureSwipe" :items="images" :options="{closeEl:true, captionEl: true, fullscreenEl: false, zoomEl: true, shareEl: false, counterEl: true, arrowEl: true, preloaderEl: true}"></vue-picture-swipe>
    </div>
    <audio v-if="!isQuestion && stop.Audio" 
        controls
        :src="`/assets/${tourId}/${stop.Ref}/`+stop.Audio" style="width:100%;background-color:black;padding:20px;height:90px;">
            Your browser does not support the
            <code>audio</code> element.
    </audio>
  <div v-if="!isQuestion && !endOfQuizz" class="stoptext" v-html="TextHTML"></div>
  <div v-if="isQuestion && !endOfQuizz">
    <div class="question" v-html="question.Question"></div>
    <div v-if="notification.text" class="notification is-light" :class="notification.class">
      <button class="delete"></button>
      {{notification.text}}
    </div>
    <div class="answer" v-for="answer in this.question.Answer" :key="answer" @click="checkAnswer(answer)">{{ answer }}</div>
  </div>
  <div v-if="endOfQuizz" style="font-size:24px;color:white;padding:24px 24px 324px 24px;">
      Bravo, vous avez terminé ce quizz.
  </div>
  </div>
    <div v-if="!previous" class="columns" style="margin:0;">
      <div class="column" style="padding:0;">
      <!-- <router-link :to="'/stop/' + tour.folder + '/' + (next)">-->
      <a @click="showNext">
        <button class="button is-large is-link is-fullwidth"><i class="fas fa-arrow-alt-circle-right" style="margin-right:6px"></i> 
            <span v-if="lang == 'french'">Suivant</span>
            <span v-else>Next</span>
        </button>
      </a>
      </div>
    </div>
    <div v-if="previous && !isQuestion" class="columns" style="margin:0;">
      <div class="column" style="padding:0;">
      <a @click="showPrevious">
        <button class="button is-large is-link is-fullwidth"><i class="fas fa-arrow-alt-circle-left" style="margin-right:6px"></i> 
          <span v-if="lang == 'french'">Précédent</span>
          <span v-else>Previous</span>
        </button>
      </a>
      </div>
      <div class="column" style="padding:0;">
      <a @click="showNext">
        <button class="button is-large is-link is-fullwidth"><i class="fas fa-arrow-alt-circle-right" style="margin-right:6px"></i> 
          <span v-if="lang == 'french'">Suivant</span>
          <span v-else>Next</span>
        </button>
      </a>
      </div>
    </div>
    <div v-if="isQuestion" class="columns" style="margin:0;">
      <div class="column" style="padding:0;">
      <!-- <router-link :to="'/stop/' + tour.folder + '/' + (next)">-->
      <a @click="showPrevious">
        <button class="button is-large is-link is-fullwidth"><i class="fas fa-arrow-alt-circle-left" style="margin-right:6px"></i> 
          <span v-if="lang == 'french'">Précédent</span>
          <span v-else>Previous</span>
        </button>
      </a>
      </div>
    </div>
    <div class="columns" style="margin:0;">
      <div class="column" style="padding:0;">
        <router-link :to="'/List/' + tour.folder">
          <button class="button is-large is-link is-fullwidth has-background-black" style="border-radius:0;"><i class="fas fa-map-marker" style="margin-right:6px"></i> 
            <span v-if="lang == 'french'">Points d'intérêt</span>
            <span v-else>Points of interest</span>
          </button>
        </router-link>
      </div>
      <div class="column" style="padding:0;">
        <router-link :to="'/Plan/' + tour.folder">
          <button class="button is-large is-link is-fullwidth has-background-black" style="border-radius:0;"><i class="fas fa-map" style="margin-right:6px"></i> Plan</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import jsonpath from "../../node_modules/jsonpath";
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import * as DefaultPhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default';

export default {
  name: "Home",
  components: {
  },
  data() {
    return {
      tour: [],
      tourId: "folleville_eglise",
      stopId: "stopId",
      title: 'title',
      description: 'description',
      stop: {},
      imageUrl: '',
      audioUrl: '',
      audioName: '',
      text: '',
      imageId:0,
      images:[],
      notification:{},
      questionId:0,
      endOfQuizz:false
    };
  },
  created() {
    this.tourId = this.$route.params.tour ? this.$route.params.tour : "eglise";
    this.stopId = this.$route.params.id ? this.$route.params.id : "stopId";
    this.tour = this.$parent.$parent.tours[this.tourId];
    this.stop = jsonpath.query(this.tour, '$.Stops[?(@.Number=="'+this.stopId+'")]')[0];
    this.text = this.stop.Text;
    this.imageUrl = this.stop.Image[this.imageId];
    let that=this;
    this.stop.Image.forEach(function(element) {
      console.log("element");
      console.log(element);
      that.images.push({
        src: `/assets/`+ that.tour.folder + '/' + that.stop.Ref + '/' + element,
        thumbnail: `/assets/`+ that.tour.folder + '/' + that.stop.Ref + '/' + element,
        w: 1000,
        h: 1000,
        title: element
      });
      //numCallbackRuns++;
    });
    console.log("this.audio");
    console.log(this.Audio);
    console.log("this.stop");
    console.log(this.stop);
    console.log(this.isQuestion);
    if(this.isQuestion() !== undefined) {
      this.question = this.stop.Questions[0];
      console.log("this.question");
      console.log(this.question);
    }
    //this.title = this.stop.Title.content;
    //this.description = this.stop.Description.content;
    //this.imageId = this.stop.AssetRef.id;
    //this.imageUrl = jsonpath.query(this.tour, '$..Asset[?(@.id=="'+this.imageId+'")].Source.uri')[0];

  },
  methods: {
    showImage: function(index) {
      this.imageId = index;
      this.imageUrl = this.stop.Image[this.imageId];
      console.log(this.imageId);
    },
    openPictureSwipe: function() {
      console.log("here");
      console.log(this.$refs.pictureSwipe);
      console.log(this.$refs.pictureSwipe.pswp);
      //jQuery("figure").click();
    },
    showNext: function() {
      this.tourId = this.$route.params.tour ? this.$route.params.tour : "eglise";
      this.stopId = this.next;
      this.tour = this.$parent.$parent.tours[this.tourId];
      this.stop = jsonpath.query(this.tour, '$.Stops[?(@.Number=="'+this.next+'")]')[0];
      this.text = this.stop.Text;
      this.imageUrl = this.stop.Image[this.imageId];
      let that=this;
      that.images = [];
      this.stop.Image.forEach(function(element) {
        console.log("element");
        console.log(element);
        that.images.push({
          src: `/assets/`+ that.tour.folder + '/' + that.stop.Ref + '/' + element,
          thumbnail: `/assets/`+ that.tour.folder + '/' + that.stop.Ref + '/' + element,
          w: 1000,
          h: 1000,
          title: element
        });
        //numCallbackRuns++;
      });
    },
    showPrevious: function() {
      this.tourId = this.$route.params.tour ? this.$route.params.tour : "eglise";
      this.stopId = this.next;
      this.tour = this.$parent.$parent.tours[this.tourId];
      this.stop = jsonpath.query(this.tour, '$.Stops[?(@.Number=="'+this.previous+'")]')[0];
      this.text = this.stop.Text;
      this.imageUrl = this.stop.Image[this.imageId];
      let that=this;
      that.images = [];
      this.stop.Image.forEach(function(element) {
        console.log("element");
        console.log(element);
        that.images.push({
          src: `/assets/`+ that.tour.folder + '/' + that.stop.Ref + '/' + element,
          thumbnail: `/assets/`+ that.tour.folder + '/' + that.stop.Ref + '/' + element,
          w: 1000,
          h: 1000,
          title: element
        });
        //numCallbackRuns++;
      });
    },    
    openGallery() {
      const pswpElement = document.querySelectorAll('.pswp')[0];
      const gallery = new PhotoSwipe(pswpElement, DefaultPhotoSwipeUI,
        this.images,
        {
          shareEl: false,
          fullscreenEl: false,
          captionEl: false
        });
      gallery.init();
    },
    checkAnswer: function(answer) {
      if(answer == this.question.Correction) {
        this.notification = {
          "class":"is-success", "text":"Bravo"
        }
        let that = this;
        if(this.stop.Questions[that.questionId+1] !== undefined) {
          setTimeout( function() {
            that.questionId = that.questionId+1;
            that.notification = {};
          }, 2000);
        } else {
          this.endOfQuizz = true;
        }
        
      } else {
        this.notification = {
          "class":"is-warning", "text":"Réessayez"
        }
      }
    }
  },
  computed: {
    TextHTML: function() {
      if(this.stop.Question !== undefined) {
        console.log("machin");
        console.log(this.stop.Text);
        return "";
      } else {
        console.log("machin");
        console.log(this.stop.Text);
        return "<p>" + this.stop.Text.replace("\n", "</p><p>") + "</p>";
      }
    },
    lang: function() {
      return this.$parent.$parent.lang;
    },
    previous: function() {
      return (this.stop.Number - 1);
    },
    next: function() {
        return (this.stop.Number + 1);
    },
    isQuestion() {
      console.log("isQuestion");
      console.log(this.stop.Questions);
      return (this.stop.Questions !== undefined);
    },
    question() {
      return this.stop.Questions[this.questionId];
    },
    questions() {
      let questions = JSON.parse(JSON.stringify(this.stop.Questions));
      return questions;
    }

  }
};
</script>

<style scoped>
img.image {
  width:100%;
}
h3 {
  width:100%;
  padding:22px 40px;
  background-color:#142846;
  color:white;
  font-weight: bold;
  font-size:24px;
}
.stoptext {
  padding:22px 40px 200px 40px;
  color:white;
  
}
.minis {
  background:black;
  /*position:absolute;
  top:10px;
  right:20px;*/
  padding:0 5px 0 5px;
  text-align: center;
}
.minis img {
  height:30px;
  width:auto;
  margin:10px 5px 5px 5px;
}
.minis span {
  margin:0;
  padding:0;
  display:inline-block;
}
.answer {
  background-color:#6384A4;
  padding:15px 15px 15px 24px;
  margin:40px;
  color:white;
  border-radius: 23px 0 0 0;
}
.question {
  padding:30px 30px 10px 30px;
  color:white;
  font-size:22px;
}
.pswp img {
    max-width: none;
    object-fit: contain;
}
</style>
