import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueRouter from 'vue-router';

import TourLink from "./components/TourLink";
import axios from "axios";
require('./assets/styles/style.scss');
import tourtools from './tourtools';
//import './../node_modules/bulma/css/bulma.css';
Vue.config.productionTip = false;

let one ="/albertnotredamedebrebiereseglise.json";
//let one_english ="/folleville_eglise.en.json";
let two ="/follevillechateau.json";
let three ="/follevillevillage.json";
const requestOne = axios.get(one);
//const requestOneEnglish = axios.get(one_english);
const requestTwo = axios.get(two);
const requestThree = axios.get(three);

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VuePictureSwipe from 'vue-picture-swipe';
Vue.component('vue-picture-swipe', VuePictureSwipe);

axios
  .all([requestOne, requestTwo, requestThree])
  .then(
    axios.spread((...responses) => {
      console.log(responses[0].data);
      let tour1 = tourtools.enrich(responses[0].data, one, "eglise", 0);
      //let tour1_english = tourtools.enrich(responses[3].data.Tour, one, 0);
      let tour2 = tourtools.enrich(responses[1].data, two, "chateau", 1);
      let tour3 = tourtools.enrich(responses[2].data, three, "village", 2);
      let tours = {}
      tours["eglise"] = tour1;
      tours["chateau"] = tour2;
      tours["village"] = tour3;

      //let lang = tourtools.getCookie("lang");
      let lang = "french";
      tourtools.setLang(lang);
      console.log(tours);

      new Vue({
        router,
        data() {
          return {
            tours: tours,
            displayMenu : false,
            lang : lang,
            menuActive : false
          };
        },
        methods: {
          french: function () {
            //console.log(tours);
            //tours[tour1.folder] = tour1;
            
            //this.$router.push('/', () => {});
            //this.displayMenu = false;
            tourtools.setLang("french");
            this.lang = "french";
            this.$router.push({ name: 'Home' });
            console.log(tourtools.getLang());
          },
          english: function() {
            tourtools.setLang("english");
            this.lang = "english";
            this.$router.push({ name: 'Home' });
            console.log(tourtools.getLang());
          },
          toggleMenu: function() {
            console.log("toggleMenu");
            console.log(this.menuActive);
            this.menuActive = !this.menuActive;
            this.displayMenu = ! this.displayMenu;
          }
        },
        components: {
          TourLink,
          VueRouter
        },
        render: h => h(App),
      }).$mount("#app");

    })
  )
  .catch(errors => {
    // react on errors.
    console.error(errors);
  });

