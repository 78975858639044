<template>
  <div class="home container" :key="refreshKey">
    <p v-html="this.$parent.lang" />
    <div v-for="tour of tours" v-bind:key="tour.index">
      <h2 v-html="tour.NameRoute" />
      <div>
        <div>
          <img class="tourImage" :src="'/assets/' + tour.folder + '/' + tour.Image[0]" />
          <div
            class="startlink"
            style="text-align: right;margin-top:-8px;border-top:6px solid black;border-bottom:6px solid black;"
          >
            <router-link v-if="lang == 'french' " class="navbar-item" :to="'/Intro/' + tour.folder">
              <i aria-hidden="true" class="mdi mdi-view-list"></i>Afficher le parcours
            </router-link>
            <router-link v-else class="navbar-item" :to="'/Intro/' + tour.folder">
              <i aria-hidden="true" class="mdi mdi-view-list"></i>Display the tour
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      tours: this.$parent.$parent.tours,
      imageId: "",
      image: {},
      imageUrl: "",
      refreshKey: 0
    };
  },
  created() {},
  methods: {},
  computed: {
    lang: function() {
      return this.$parent.$parent.lang;
    }
  }
};
</script>

<style scoped lang="scss">
  .startlink {
    background-color:#426394;
    padding: 20px 8px 12px 8px;
    color: white;
    a {
      color: white;
    }
  }
  figure img {
    width:100%;
  }
  .home h2 {
    background-color:#224374;
    font-size:22px;
    font-weight: bold;  
    color:white;
    padding:10px 22px;
  }
  img.tourImage {
    object-fit: cover;
    width:100%;
    height:340px;
    object-position: top;
  }
</style>
