<template>
  <div class="home container">
    <div style="background-color:white;">
      <section class="hero is-primary">
        <div class="hero-body">
          <p class="title">
            {{tour.NameRoute}}
          </p>
          <p  v-if="lang == 'french' " class="subtitle">
            {{tour.TextFR}}
          </p>
          <p  v-else class="subtitle">
            {{tour.TextEN}}
          </p>
          <p><i class="fas fa-map-marker"></i> {{tour.NbStops}} points - <i class="fas fa-walking"></i> {{tour.Distance}} - <i class="fas fa-clock"></i> {{tour.Duration}}</p>

        </div>
      </section>
      
      <div v-for="(stop, id) in langStops" v-bind:key="id">
        <div style="border-bottom:2px solid white;margin-top:20px;padding: 0px 10px;">
        <!-- {{ stop.view }}<br/> -->
          <a :href="'/stop/' +  tourId +'/'+ stop.Ref">
              {{ stop.Ref +" : "+ stop.Title }}
          </a>

        </div>
      </div>
        <a class="" :href="'/Plan/' + tour.folder">
          <button class="button is-large is-link is-fullwidth">Voir le plan</button>
        </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import jsonpath from "../../node_modules/jsonpath";
import _ from "../../node_modules/lodash";
//import tourtools from '../tourtools';

export default {
  name: "Home",
  components: {
  },
  data() {
    return {
      tour: {},
      imageUrl: ""
    };
  },
  methods: {
    videconsole() {
      console.log(this.eglise);
    }
  },
  created() {
    console.log(this.$route.params.tour);
    console.log(this.$parent.$parent.tours);
    this.tourId = this.$route.params.tour ? this.$route.params.tour : "eglise";
    this.stopId = this.$route.params.id ? this.$route.params.id : "stopId";
    this.tour = this.$parent.$parent.tours[this.tourId];
    console.log(this.tour);
    
    

    //this.imageUrl = this.tour.imageUrl;
  },
  computed: {
    orderedStopGroups: function () {
      return _.orderBy(this.tour.Stop, 'Title.content', 'asc');
    },
    lang: function() {
      return this.$parent.$parent.lang;
    },
    langStops: function() {
      let lang = this.lang;
      return this.tour.Stops.filter(function(value){
        if(lang == "french") {
          return (value.Number < 100);
        } else {
          return (value.Number > 100);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
  .startlink {
    background:#6b0100;
    padding:20px 8px 12px 8px;
    color:white;
    a {
      color:white;
    }
  }
  figure img {
    width:100%;
  }
</style>
