<template>
  <div class="tourlink" style="">
    <template v-if="view == 'stop_group'">
    <a :href="target"><i class="mdi mdi-map-legend" aria-hidden="true"></i>{{title}}</a>
    </template>
    <template v-else-if="view == 'image_stop'">
    <a :href="target"><i class="mdi mdi-image-outline" aria-hidden="true"></i>{{title}}</a>
    </template>
    <template v-else>
    <a :href="target">{{view}} {{title}}</a>
    </template>
  </div>
</template>

<script>
  module.exports = {
    data: function() {
      return {
        text: "bonjour"
      }
    },
    props: ['view', 'title', 'target']
  }
</script>

<style scoped lang="scss">
  .tourlink {
    background:#6b0100;
    padding:12px 8px 0px 8px;
    color:white;
    a {
      color:white;
    }
  }
</style>
