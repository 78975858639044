<template>
  <div class="home container">
    <div style="background-color:white;">
      <section class="hero is-primary">
        <div class="hero-body">
          <p class="title">
            {{tour.NameRoute}}
          </p>
          <p><i class="fas fa-map-marker"></i> {{tour.NbStops}} points - <i class="fas fa-walking"></i> {{tour.Distance}} - <i class="fas fa-clock"></i> {{tour.Duration}}</p>

        </div>
      </section>
      <div id="plan" style="position:relative;">
        <img :src="`/assets/`+ tour.folder + '/' + tour.Plan" />
        <span v-for="(stop, id) in langStops" v-bind:key="id">
            <router-link v-if="stop.x" :to="'/stop/' +  tourId +'/'+ stop.Ref" :style="'position:absolute;left:'+stop.x+'%;top:'+stop.y+'%;color:white;background-color:#365685;border-radius:4px;font-size:18px;padding:4px 8px;'">
              {{ stop.Ref }}
            </router-link>
        </span>
      </div>
        <router-link :to="'/List/' + tour.folder"> 
          <button v-if="lang == 'french'" class="button is-large is-link is-fullwidth">Voir tous les points d'intérêt</button>
          <button v-else class="button is-large is-link is-fullwidth">View all points of interest</button>
        </router-link>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import jsonpath from "../../node_modules/jsonpath";
import _ from "../../node_modules/lodash";
import tourtools from '../tourtools';

export default {
  name: "Intro",
  components: {
  },
  data() {
    return {
      tour: {},
      imageUrl: ""
    };
  },
  methods: {
    videconsole() {
      console.log(this.eglise);
    }
  },
  created() {
    //console.log(this.$route.params.tour);
    //console.log(this.$parent.$parent.tours);
    this.tourId = this.$route.params.tour ? this.$route.params.tour : "eglise";
    this.stopId = this.$route.params.id ? this.$route.params.id : "stopId";
    this.tour = this.$parent.$parent.tours[this.tourId];
    this.Image = this.tour.Image[0];
    let lang = this.lang;
    this.langStops = this.tour.Stops.filter(function(value){
      console.log(tourtools.getLang());
      if(lang == "french") {
        return (value.Number < 100);
      } else {
        return (value.Number > 100);
      }
      
    })
    //console.log(langStops);
    
    //this.imageUrl = this.tour.imageUrl;
  },
  computed: {
    orderedStopGroups: function () {
      return _.orderBy(this.langStops, 'Title.content', 'asc');
    },
    lang: function() {
      return this.$parent.$parent.lang;
    }
  }
};
</script>

<style scoped lang="scss">
  .startlink {
    background:#6b0100;
    padding:20px 8px 12px 8px;
    color:white;
    a {
      color:white;
    }
  }
  figure img {
    width:100%;
  }
</style>
