import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Stop from "../views/Stop";
import Imagestop from "../views/Imagestop";
import List from "../views/List";
import Intro from "../views/Intro";
import Plan from "../views/Plan";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/list",
    name: "List",
    component: List
  },
  {
    path: "/list/:tour",
    name: "List2",
    component: List
  },
  {
    path: "/intro/:tour",
    name: "Intro",
    component: Intro
  },  
  {
    path: "/plan/:tour",
    name: "Plan",
    component: Plan
  },  
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/stop/:tour/:id",
    name: "Stop",
    component: Stop
  },
  {
    path: "/stop_group/:id",
    name: "Stopgroup",
    component: Stop
  },
  {
    path: "/image_stop/:tour/:id",
    name: "ImageStop2",
    component: Imagestop
  },
  {
    path: "/image_stop/:id",
    name: "ImageStop",
    component: Imagestop
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
